import React, { useState, useMemo, useEffect, useContext } from 'react'
import TransparentButton from '../../common/Buttons/transparentButton'
import ReactTooltip from 'react-tooltip'
import Pagination from '../../common/Pagination'
import { useWeb3React } from '@web3-react/core'
import Sticky from 'react-stickynode'
import useWalletModal from '../../../hooks/useWalletModal'
import { formatAmount, getLP0Symbol, getLP1Symbol, ZERO_ADDRESS } from '../../../utils/formatNumber'
import { NumberOfRows } from '../../../config/constants'
import NoFound from '../../common/NoFound'
import MigrateModal from './migrateModal'
import { FusionsContext } from '../../../context/FusionsContext'
import UnstakeModal from './unstakeModal'

const ItemWithTooltip = ({ usd, content, account, idx, type }) => {
  const [arrowReverse, setArrowReverse] = useState()
  return account || type === 'tvl' ? (
    <div className='flex flex-col items-start justify-center'>
      <div
        data-tip
        data-for={`${type}-${idx}`}
        onMouseEnter={() => {
          setArrowReverse(`${type}-${idx}`)
        }}
        onMouseLeave={() => {
          setArrowReverse(null)
        }}
        className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px] flex items-center cursor-pointer space-x-1.5'
      >
        <p>{'$' + formatAmount(usd, true)}</p>
        <button className={`${arrowReverse === `${type}-${idx}` ? 'rotate-180' : 'rotate-0'} transform transition-all duration-300 ease-in-out`}>
          <img alt='' src='/images/liquidity/small-arrow-bottom.svg' />
        </button>
      </div>
      <ReactTooltip
        className='max-w-[180px] !bg-[#090333] !border !border-blue !text-[#E6E6E6] !text-base !py-[9px] !px-6 !opacity-100 after:!bg-body'
        id={`${type}-${idx}`}
        place='right'
        effect='solid'
      >
        {content}
      </ReactTooltip>
    </div>
  ) : (
    <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px]'>-</div>
  )
}

const TableRow = ({ item, isLast, idx, account }) => {
  const [isOpen, setIsOpen] = useState(!item.account.gaugeBalance.isZero())
  const { openWalletModal } = useWalletModal()
  const [migrate, setMigrate] = useState(false)
  const [unstake, setUnstake] = useState(false)
  const fusions = useContext(FusionsContext)
  const gammas = useMemo(() => {
    return fusions
      .filter((ele) => ele.gauge.address !== ZERO_ADDRESS && ele.isValid)
      .filter(
        (ele) =>
          (ele.token0.address === item.token0.address && ele.token1.address === item.token1.address) ||
          (ele.token0.address === item.token1.address && ele.token1.address === item.token0.address),
      )
  }, [fusions])

  return (
    <div
      key={idx}
      className={`
    ${isLast ? 'rounded-b-[5px]' : ''}
    ${idx === 0 && 'rounded-t-lg'}
    mb-px flex flex-wrap lg:flex-nowrap items-start lg:items-center w-full justify-between  text-[#DEDBF2] p-4 lg:py-5 px-4 xl:px-6 bg-[#16033A]`}
    >
      <div className='w-full  lg:w-[20%]'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center space-x-3 mr:0 lg:mr-3'>
            <div className='flex items-center -space-x-2'>
              <img className='relative z-10' alt='' src={item.token0.logoURI} width={30} height={30} />
              <img className='relative z-[5]' alt='' src={item.token1.logoURI} width={30} height={30} />
            </div>
            <div>
              <div className='flex flex-wrap text-base xl:text-[19px] leading-[30px] font-medium'>
                <span>{getLP0Symbol(item)}</span>/<span>{getLP1Symbol(item)}</span>
              </div>
              <p className='tracking-[0.78px] text-[13px] leading-none'>{item.title}</p>
            </div>
          </div>
          <button
            className='lg:hidden'
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            <img alt='' className={`${isOpen ? 'rotate-180' : ''} transform`} src='/images/swap/dropdown-arrow.png' />
          </button>
        </div>
      </div>
      <div className='flex flex-col mt-2 lg:mt-0 w-1/2 lg:w-[14%] items-start justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>APR</p>
        <div className='flex flex-col items-start justify-center text-base sm:text-[17px] lg:text-[15px] xl:text-[17px]'>
          {formatAmount(item.gauge.apr, true)}%
        </div>
      </div>
      <div className='flex flex-col w-1/2 mt-2 lg:mt-0 items-start lg:w-[11%] justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Total Staked</p>
        <ItemWithTooltip
          account={account}
          type={'tvl'}
          usd={item.gauge.tvl}
          content={
            <>
              {formatAmount(item.gauge.pooled0)} {item.token0.symbol}
              <br />
              {formatAmount(item.gauge.pooled1)} {item.token1.symbol}
            </>
          }
          idx={idx}
        />
      </div>
      {/* second row */}
      <div className={`${isOpen ? 'flex' : 'hidden'} lg:flex flex-col items-start mt-3 lg:mt-0 w-1/2 lg:w-[11%] justify-center`}>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>My Pool</p>
        <ItemWithTooltip
          account={account}
          type={'pool'}
          usd={item.account.totalUsd}
          content={
            <>
              {formatAmount(item.account.total0)} {item.token0.symbol}
              <br />
              {formatAmount(item.account.total1)} {item.token1.symbol}
            </>
          }
          idx={idx}
        />
      </div>
      <div className={`${isOpen ? 'flex' : 'hidden'} lg:flex flex-col items-start w-1/2 mt-3 lg:mt-0 lg:w-[11%] justify-center`}>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>My Stake</p>
        <ItemWithTooltip
          account={account}
          type={'stake'}
          usd={item.account.stakedUsd}
          content={
            <>
              {formatAmount(item.account.staked0)} {item.token0.symbol}
              <br />
              {formatAmount(item.account.staked1)} {item.token1.symbol}
            </>
          }
          idx={idx}
        />
      </div>
      <div className={`${isOpen ? 'block' : 'hidden'} lg:block  w-1/2 lg:w-[8%] mt-2 lg:mt-0`}>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Earnings</p>
        <ItemWithTooltip
          account={account}
          type={'earning'}
          usd={item.account.earnedUsd}
          content={<>{formatAmount(item.account.gaugeEarned)} THE</>}
          idx={idx}
        />
      </div>
      <div className={`${isOpen ? 'block' : 'hidden'} lg:block w-full lg:w-[25%] mt-3.5 lg:mt-0`}>
        {account ? (
          gammas.length > 0 && (
            <div className='space-x-5 xl:space-x-2 w-full flex items-center lg:justify-end'>
              <TransparentButton
                content={'Migrate'}
                onClickHandler={() => {
                  setMigrate(true)
                }}
                fontWeight={'font-medium'}
                className='h-10 px-[26px] text-white flex whitespace-nowrap flex-col items-center justify-center text-sm text-[17px] w-1/2'
              />
              <TransparentButton
                content={'Unstake'}
                onClickHandler={() => {
                  setUnstake(true)
                }}
                fontWeight={'font-medium'}
                className='h-10 px-[26px] text-white flex whitespace-nowrap flex-col items-center justify-center text-sm text-[17px] w-1/2'
              />
            </div>
          )
        ) : (
          <div className='w-full  flex  items-center lg:justify-end'>
            <TransparentButton
              onClickHandler={openWalletModal}
              content={'Connect Wallet'}
              className='
  h-10 px-[26px] text-white flex lg:max-w-[173px] whitespace-nowrap flex-col items-center justify-center tex-sm xl:text-[17px] w-full '
            />
          </div>
        )}
      </div>
      {migrate && <MigrateModal isOpen={migrate} setIsOpen={setMigrate} pair={item} gammas={gammas} />}
      {unstake && <UnstakeModal isOpen={unstake} setIsOpen={setUnstake} pair={item} />}
    </div>
  )
}

const TablePairs = ({ pairsData, sort, setSort, sortOptions, /* active, */ filter, searchText, isStaked }) => {
  const [pageSize, setPageSize] = useState(NumberOfRows[0])
  const [currentPage, setCurrentPage] = useState(0)
  const { account } = useWeb3React()

  const pageCount = useMemo(() => {
    return Math.ceil(pairsData.length / pageSize)
  }, [pairsData, pageSize])

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  }

  // useEffect(() => {
  //   setCurrentPage(0)
  // }, [pageSize, active, filter])

  useEffect(() => {
    setCurrentPage(0)
  }, [pageSize, filter, searchText, isStaked])

  return (
    <>
      {/* for desktop */}
      <div className='w-full mt-11'>
        <div className='w-full'>
          {pairsData.slice(currentPage * pageSize, (currentPage + 1) * pageSize).length > 0 ? (
            <>
              <Sticky
                enabled={true}
                innerActiveClass={'gradientBorder'}
                top={95}
                bottomBoundary={1200}
                activeClass={''}
                innerClass={'px-6 lg:flex justify-between hidden z-[100] py-[0.475rem] lg:!-mb-[19px] xl:!mb-0 lg:!top-[-19px] xl:!top-[0]'}
                className={`z-[100]`}
              >
                <div className='w-[20%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'></div>
                {sortOptions.map((option, index) => (
                  <div
                    className={`${index === 4 ? 'w-[8%]' : index === 0 ? 'w-[14%]' : 'w-[11%]'} font-medium text-[17px] xl:text-[18px] text-white f-f-fg`}
                    key={`header-${index}`}
                  >
                    <div
                      onClick={() => {
                        setSort({
                          ...option,
                          isDesc: sort.value === option.value ? !sort.isDesc : true,
                        })
                      }}
                      className='flex items-center cursor-pointer space-x-1 -ml-1 relative'
                    >
                      {sort.value === option.value && (
                        <button className={`${sort.isDesc ? '' : 'rotate-180'} transform absolute -left-3.5`}>
                          <img alt='' src='/images/liquidity/arrow-bottom.svg' />
                        </button>
                      )}
                      <p className='flex items-center'>{option.label}</p>
                    </div>
                  </div>
                ))}
                <div className='w-[25%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'></div>
              </Sticky>
              <div className='flex flex-col rounded-[5px] gradient-bg p-px shadow-box'>
                {pairsData.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((item, idx) => {
                  return (
                    <TableRow
                      account={account}
                      isLast={idx === pairsData.slice(currentPage * pageSize, (currentPage + 1) * pageSize).length - 1}
                      idx={idx}
                      item={item}
                      key={`row-${idx}`}
                    />
                  )
                })}
              </div>
              <Pagination
                pageSize={pageSize}
                setPageSize={setPageSize}
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                currentPage={currentPage}
                total={pairsData.length}
              />
            </>
          ) : (
            <NoFound title={account ? 'No pools found' : 'Please connect your wallet'} />
          )}
        </div>
      </div>
    </>
  )
}

export default TablePairs
