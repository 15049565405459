import React from 'react'
import { useLocation } from 'react-router-dom'
import './style.scss'

const Footer = () => {
  const route = useLocation()
  const links = [
    {
      img: '/images/footer/twitter.png',
      url: 'https://twitter.com/ThenaFi_',
    },
    {
      img: '/images/footer/medium.png',
      url: 'https://medium.com/@ThenaFi',
    },
    {
      img: '/images/footer/discord.png',
      url: 'https://discord.gg/thena',
    },
    {
      img: '/images/footer/telegram.png',
      url: 'https://t.me/+Lr-8OJpzxBo4Yjg0',
    },
    {
      img: '/images/footer/geckoterminal.png',
      url: 'https://www.geckoterminal.com/bsc/thena/pools',
    },
  ]
  return (
    <div
      id='footer'
      className={`footer-wrap pt-[200px] lg:[150px] xl:pt-[303px] xxxl:pt-[20%] relative ${route.pathname.includes('referral') ? 'hidden' : 'block'} lg:block`}
    >
      <img alt='' src='/images/footer/bg-footer.png' className='w-full bg-index desktop-1 absolute bottom-0' />
      <img alt='' src='/images/footer/bg-footer3.png' className='bg-index mobile-1 absolute bottom-0 w-full' />
      <div className={`mx-auto container-1 w-full relative lg:block`}>
        <div className='w-full content-wrapper-footer'>
          <div className='links-wrapper'>
            <p>Copyright © 2023 Thena. All rights reserved.</p>
            <div className='flex items-center space-x-[14px]'>
              {links.map((item, idx) => {
                return (
                  <a key={idx} href={item.url} rel='noreferrer' target={'_blank'}>
                    <img alt='' className='max-w-[44px]' src={item.img} />
                  </a>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
