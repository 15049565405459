import React, { useState, useMemo } from 'react'
import TransparentButton from '../../common/Buttons/transparentButton'
import StyledButton from '../../common/Buttons/styledButton'
import CommonHollowModal from '../../common/CommonHollowModal'
import BalanceInput from '../../common/Input/BalanceInput'
import { useUnstake } from '../../../hooks/useGauge'
import { getLPSymbol, isInvalidAmount } from '../../../utils/formatNumber'
import { customNotify } from '../../../utils/notify'

const UnstakeModal = ({ isOpen, setIsOpen, pair }) => {
  const [withdrawAmount, setWithdrawAmount] = useState('')
  const { onUnstake, pending: unstakePending } = useUnstake()

  const unstakeErrorMsg = useMemo(() => {
    if (pair) {
      if (isInvalidAmount(withdrawAmount)) {
        return 'Invalid Amount'
      }
      if (pair.account.gaugeBalance.lt(withdrawAmount)) {
        return 'Insufficient ' + pair.symbol + ' Balance'
      }
    }
    return null
  }, [withdrawAmount, pair])

  return (
    <CommonHollowModal popup={isOpen} width={588} setPopup={setIsOpen} title={`Unstake ${getLPSymbol(pair)} LP (${pair.stable ? 'STABLE' : 'VOLATILE'})`}>
      <div className='w-full mt-[29px] flex items-center justify-center flex-col'>
        <div className='w-full flex items-center justify-center flex-col mt-5'>
          <BalanceInput
            title='Amount'
            inputAmount={withdrawAmount}
            setInputAmount={setWithdrawAmount}
            symbol={pair.symbol}
            balance={pair.account.gaugeBalance}
            logoURIs={[pair.token0.logoURI, pair.token1.logoURI]}
          />
          <div className='flex items-center mt-[26px] w-full space-x-5'>
            <StyledButton
              disabled={unstakePending}
              onClickHandler={() => {
                if (unstakeErrorMsg) {
                  customNotify(unstakeErrorMsg, 'warn')
                  return
                }
                onUnstake(pair, withdrawAmount)
              }}
              content={unstakePending ? 'PENDING...' : 'UNSTAKE LP'}
              className='py-[13px] md:py-[14.53px] w-1/2 tracking-[1.12px] md:tracking-[1.44px] text-white flex items-center justify-center text-[13px] md:text-[17px] font-bold px-[23px] rounded-[3px]'
            />
            <TransparentButton
              onClickHandler={() => setIsOpen(false)}
              content={'CANCEL'}
              className='
                py-[13px] md:py-[14.53px] px-[26px] text-white flex items-center justify-center text-[13px] md:text-[17px] w-1/2 tracking-[1.12px] md:tracking-[1.44px] font-semibold'
            />
          </div>
        </div>
      </div>
    </CommonHollowModal>
  )
}

export default UnstakeModal
