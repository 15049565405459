import React, { useState, useEffect, useContext } from 'react'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import useRefresh from '../hooks/useRefresh'
import { getFusions } from '../utils/api'
import { PoolTypes } from '../config/constants'
import { BaseAssetsConetext } from './BaseAssetsConetext'

const Presets = {
  SAFE: 'SAFE',
  RISK: 'RISK',
  NORMAL: 'NORMAL',
  FULL: 'FULL',
  STABLE: 'STABLE',
  GAMMA_NARROW: 'GAMMA_NARROW',
  GAMMA_WIDE: 'GAMMA_WIDE',
  GAMMA_DYNAMIC: 'GAMMA_DYNAMIC',
  GAMMA_STABLE: 'GAMMA_STABLE',
}

const FusionsContext = React.createContext([])

const FusionsContextProvider = ({ children }) => {
  const [fusions, setFusions] = useState([])
  const { fastRefresh } = useRefresh()
  const { account } = useWeb3React()
  const assets = useContext(BaseAssetsConetext)

  useEffect(() => {
    const getUserData = async () => {
      try {
        const { data: fusions } = await getFusions()
        if (fusions.length > 0) {
          let bnbTheNarrow = '0xed044cd5654ad208b1bc594fd108c132224e3f3c'
          let bnbTheWide = '0xe8ec29b75d98d3cdc47db9797b00dcaabea2b15b'
          var bnbthe = '0x63Db6ba9E512186C2FAaDaCEF342FB4A40dc577c'
          var busdthe = '0x34B897289fcCb43c048b2Cea6405e840a129E021'
          var usdtthe = '0xA051eF9A6FBea340Bb734d022e7B6a3aD9fD9B06'
          const userInfo = fusions
            .map((fusion) => {
              const asset0 = assets.find((ele) => ele.address.toLowerCase() === fusion.token0.address.toLowerCase())
              const asset1 = assets.find((ele) => ele.address.toLowerCase() === fusion.token1.address.toLowerCase())
              const token0 = {
                address: fusion.token0.address,
                symbol: asset0?.symbol || 'UNKNOWN',
                decimals: asset0?.decimals || 18,
                logoURI: asset0?.logoURI || 'https://thena.fi/images/tokens/UKNOWN.png',
                price: asset0?.price || 0,
              }
              const token1 = {
                address: fusion.token1.address,
                symbol: asset1?.symbol || 'UNKNOWN',
                decimals: asset1?.decimals || 18,
                logoURI: asset1?.logoURI || 'https://thena.fi/images/tokens/UKNOWN.png',
                price: asset1?.price || 0,
              }
              const token0Reserve = new BigNumber(fusion.token0.reserve)
              const token1Reserve = new BigNumber(fusion.token1.reserve)
              let totalTvl
              if (token0.price > 0 && token1.price > 0) {
                totalTvl = token0Reserve.times(token0.price).plus(token1Reserve.times(token1.price))
              } else if (token0.price > 0) {
                totalTvl = token0Reserve.times(token0.price).times(2)
              } else if (token1.price > 0) {
                totalTvl = token1Reserve.times(token1.price).times(2)
              } else {
                totalTvl = new BigNumber(0)
              }
              const lpPrice = fusion.totalSupply > 0 ? totalTvl.div(fusion.totalSupply) : new BigNumber(0)
              const gaugeTvl = lpPrice.times(fusion.gauge.totalSupply)
              let kind
              if (fusion.isGamma) {
                if (['narrow', 'wide'].includes(fusion.type.toLowerCase())) {
                  kind = PoolTypes.FUSION
                } else {
                  kind = PoolTypes.STABLE
                }
              } else {
                if (fusion.type.toLowerCase() === 'stable') {
                  kind = PoolTypes.STABLE
                } else {
                  kind = PoolTypes.V1
                }
              }
              return {
                ...fusion,
                isValid: true,
                stable: fusion.type === 'Stable',
                title: Presets[(fusion.isGamma ? 'GAMMA_' : '') + fusion.type.toUpperCase()] || 'VOLATILE',
                kind,
                tvl: totalTvl,
                token0: {
                  ...token0,
                  reserve: new BigNumber(fusion.token0.reserve),
                },
                token1: {
                  ...token1,
                  reserve: new BigNumber(fusion.token1.reserve),
                },
                gauge: {
                  ...fusion.gauge,
                  tvl: gaugeTvl,
                  apr: new BigNumber(fusion.gauge.apr),
                },
              }
            })
            .sort((a, b) => {
              return a.gauge.tvl.minus(b.gauge.tvl).times(-1).toNumber()
            })
            .sort(function (x, y) {
              return x.address == busdthe.toLowerCase() ? -1 : y.address == busdthe ? 1 : 0
            })
            .sort(function (x, y) {
              return x.address == bnbthe.toLowerCase() ? -1 : y.address == bnbthe ? 1 : 0
            })
            .sort(function (x, y) {
              return x.address == usdtthe.toLowerCase() ? -1 : y.address == usdtthe ? 1 : 0
            })
            .sort(function (x, y) {
              return x.address == bnbTheWide.toLowerCase() ? -1 : y.address == bnbTheWide ? 1 : 0
            })
            .sort(function (x, y) {
              return x.address == bnbTheNarrow.toLowerCase() ? -1 : y.address == bnbTheNarrow ? 1 : 0
            })
          setFusions(userInfo)
        }
      } catch (e) {
        console.error('user fusions fetched had error', e)
      }
    }
    if (assets.length > 0) {
      getUserData()
    }
  }, [account, assets, fastRefresh])

  return <FusionsContext.Provider value={fusions}>{children}</FusionsContext.Provider>
}

export { FusionsContext, FusionsContextProvider }
