import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TransactionType } from '../../../config/constants'
import { closeTransaction } from '../../../state/transactions/actions'
import Modal from '../Modal'
import Spinner from '../Spinner'

const Transaction = () => {
  const { popup, title, transactions, final, link } = useSelector((state) => state.transactions)
  const dispatch = useDispatch()

  const setPopup = useCallback(
    (value) => {
      if (!value) {
        dispatch(closeTransaction())
      }
    },
    [dispatch],
  )

  const txns = useMemo(() => {
    return Object.values(transactions)
  }, [transactions])

  return (
    <Modal isTransaction={true} width={540} popup={popup} setPopup={setPopup} title={final ? '' : title} disableOutside={true}>
      {final ? (
        <div className='w-full flex flex-col items-center justify-center sm:min-w-[450px] lg:max-w-[540px]'>
          <img alt='' className='my-8' src='/images/swap/big-checkmark.svg' />
          <div className='max-w-[266px] md:max-w-[330px] w-full flex flex-col items-center'>
            <p className='text-white text-[27px] f-f-fg text-center'>{final}</p>
            <p className='my-3 text-dimGray text-base md:text-[19px] leading-6 text-center'>Transaction has been confirmed by the blockchain.</p>
            {/* {txns
              .filter((tx) => !!tx.hash)
              .map((tx, index) => (
                <p
                  className='mb-1 text-green text-sm md:text-base leading-6 cursor-pointer flex items-center underline underline-offset-4'
                  onClick={() => {
                    window.open(`https://bscscan.com/tx/${tx.hash}`, '_blank')
                  }}
                  key={`tx-${index}`}
                >
                  {tx.desc}
                  <img src='/images/svgs/link.svg' className='ml-1 text-green' alt='link' />
                </p>
              ))} */}
          </div>
          <button
            onClick={() => {
              if (link) {
                window.open(link)
              }
              setPopup(false)
            }}
            className={`w-full py-2 md:py-5 connect-wallet-btn text-white text-base md:text-lg leading-8 tracking-[1.44px] f-f-fg transition-all duration-300 ease-out mt-9 font-bold rounded-[3px]`}
          >
            {link ? 'CLOSE AND REDIRECT' : 'CLOSE'}
          </button>
        </div>
      ) : (
        <div className='bg-[#0D1238] px-6 py-[18px] rounded-[3px] mt-4 sm:min-w-[450px] lg:max-w-[540px]'>
          {txns.map((tx, index) => {
            return txns.length === index + 1 ? (
              <div className='flex items-center justify-between' key={`tx-${index}`}>
                <p className={`text-white text-sm md:text-base leading-5`}>{tx.desc}</p>
                {tx.status === TransactionType.SUCCESS && <img alt='' src='/images/swap/success-mark.svg' />}
                {tx.status === TransactionType.WAITING && <img alt='' src='/images/swap/pending-mark.svg' />}
                {tx.status === TransactionType.PENDING && <Spinner />}
                {tx.status === TransactionType.FAILED && <img alt='' src='/images/swap/failed-mark.svg' />}
              </div>
            ) : (
              <div className='flex items-center justify-between pb-3 mb-3 border-b border-[#5E6282]' key={`tx-${index}`}>
                <p className={`text-white text-sm md:text-base leading-5`}>{tx.desc}</p>
                {tx.status === TransactionType.SUCCESS && <img alt='' src='/images/swap/success-mark.svg' />}
                {tx.status === TransactionType.WAITING && <img alt='' src='/images/swap/pending-mark.svg' />}
                {tx.status === TransactionType.PENDING && <Spinner />}
                {tx.status === TransactionType.FAILED && <img alt='' src='/images/swap/failed-mark.svg' />}
              </div>
            )
          })}
        </div>
      )}
    </Modal>
  )
}

export default Transaction
