import { Route, Routes } from 'react-router-dom'
import Migration from './pages/liquidity'
import Rewards from './pages/rewards'
import Header from './components/common/Header'
import Footer from './components/common/Footer'
import PageNotFound from './pages/404NotFound'
import { RefreshContextProvider } from './context/RefreshContext'
import { ToastContainer, Zoom } from 'react-toastify'
import { useVideoAutoplay } from './helpers/useAutoPlay'
import './App.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import { VeTHEsContextProvider } from './context/veTHEsConetext'
import { BaseAssetsConetextProvider } from './context/BaseAssetsConetext'
import { RouteAssetsConetextProvider } from './context/RouteAssetsConetext'
import { PairsContextProvider } from './context/PairsContext'
import { FusionsContextProvider } from './context/FusionsContext'

const App = () => {
  useVideoAutoplay()

  return (
    <div className='main'>
      <RefreshContextProvider>
        <BaseAssetsConetextProvider>
          <RouteAssetsConetextProvider>
            <PairsContextProvider>
              <VeTHEsContextProvider>
                <FusionsContextProvider>
                  <Header />
                  <Routes>
                    <Route path='/' element={<Migration />} exact />
                    <Route path='/rewards' element={<Rewards />} exact />
                    <Route path='/404' element={<PageNotFound />} exact />
                    <Route path='*' element={<PageNotFound />} exact />
                  </Routes>
                  <Footer />
                </FusionsContextProvider>
              </VeTHEsContextProvider>
            </PairsContextProvider>
          </RouteAssetsConetextProvider>
        </BaseAssetsConetextProvider>
      </RefreshContextProvider>
      <ToastContainer
        className='notify-class'
        position='top-right'
        theme='dark'
        closeOnClick={false}
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={true}
        closeButton={false}
      />
    </div>
  )
}

export default App
